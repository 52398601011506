import { CommonModule } from '@angular/common'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { generateUUID } from 'src/app/core/utils/app-util'

@Component({
  selector: 'app-image-lazy-load',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './image-lazy-load.component.html',
  styleUrl: './image-lazy-load.component.scss'
})
export class ImageLazyLoadComponent implements OnInit, OnDestroy {
  uuid = generateUUID()

  @Input()
  loadDelay = 250

  @Input()
  width = 32

  @Input()
  height = 32

  showContent = false

  ngOnInit(): void {
    ImageLazyLoadComponent.lazyImagesInQueue.push(this)
    ImageLazyLoadComponent.doQueueTask()
  }

  ngOnDestroy(): void {
    ImageLazyLoadComponent.lazyImagesInQueue =
      ImageLazyLoadComponent.lazyImagesInQueue.filter(
        (c) => c.uuid !== this.uuid
      )
  }

  static lazyImagesInQueue: ImageLazyLoadComponent[] = []
  static lazyTaskBackground?: NodeJS.Timeout
  static doQueueTask() {
    if (ImageLazyLoadComponent.lazyTaskBackground !== undefined) return
    ImageLazyLoadComponent.lazyTaskBackground = setInterval(() => {
      const next = ImageLazyLoadComponent.lazyImagesInQueue.shift()
      if (next === undefined) {
        clearInterval(ImageLazyLoadComponent.lazyTaskBackground)
        ImageLazyLoadComponent.lazyTaskBackground = undefined
      } else {
        next.showContent = true
      }
    }, 1)
  }
}
