<div class="rounded-top emoji-selector h-100" #emojiSelector>
  <div class="dragger" (mousedown)="startDrag($event)" *ngIf="!mobileSelector">
    <fa-icon [icon]="faUpDownLeftRight" size="lg" class="me-2"></fa-icon> <span [innerHTML]="'common.input.emoji-picker.title' | translate"></span>
  </div>
  <div class="p-fluid p-2 search-box rounded-top" (mousedown)="startDrag($event)">
    <input type="text" pInputText [placeholder]="'common.input.emoji-picker.search-emoji' | translate" [ngModel]="searchTerm" (ngModelChange)="updateSearch($event)" (mousedown)="$event.stopPropagation()" />
  </div>
  <div class="d-flex flex-row wrapper rounded-bottom">
    <div class="category p-2">
      <div class="button d-flex align-items-center justify-content-center category-guild-icon" [class.selected]="activeCategory === 'guild'" (click)="jumpTo('guild')" *ngIf="guildEmojis.length > 0">
        <img *ngIf="guildIconUrl !== undefined" [ngSrc]="guildIconUrl" width="34" height="34" style="border-radius: 5px;" />
        <div class="no-icon" *ngIf="guildIconUrl === undefined">
          {{ guildName.toUpperCase().charAt(0) }}
        </div>
      </div>
      <div class="button d-flex align-items-center justify-content-center" [class.selected]="activeCategory === 'persons'" (click)="jumpTo('persons')">
        <fa-icon [icon]="faFaceSmile"></fa-icon>
      </div>
      <div class="button d-flex align-items-center justify-content-center" [class.selected]="activeCategory === 'nature'" (click)="jumpTo('nature')">
        <fa-icon [icon]="faLeaf"></fa-icon>
      </div>
      <div class="button d-flex align-items-center justify-content-center" [class.selected]="activeCategory === 'food'" (click)="jumpTo('food')">
        <fa-icon [icon]="faBurger"></fa-icon>
      </div>
      <div class="button d-flex align-items-center justify-content-center" [class.selected]="activeCategory === 'activities'" (click)="jumpTo('activities')">
        <fa-icon [icon]="faGamepad"></fa-icon>
      </div>
      <div class="button d-flex align-items-center justify-content-center" [class.selected]="activeCategory === 'travels'" (click)="jumpTo('travels')">
        <fa-icon [icon]="faBicycle"></fa-icon>
      </div>
      <div class="button d-flex align-items-center justify-content-center" [class.selected]="activeCategory === 'objects'" (click)="jumpTo('objects')">
        <fa-icon [icon]="faLaptop"></fa-icon>
      </div>
      <div class="button d-flex align-items-center justify-content-center" [class.selected]="activeCategory === 'symbols'" (click)="jumpTo('symbols')">
        <fa-icon [icon]="faHeart"></fa-icon>
      </div>
      <div class="button d-flex align-items-center justify-content-center" [class.selected]="activeCategory === 'flags'" (click)="jumpTo('flags')" > 
        <fa-icon [icon]="faFlag"></fa-icon>
      </div>
    </div>
    <div class="selector-wrapper w-100 d-flex flex-column">
      <div *ngIf="activeCategory === 'search' && visibleEmojis[0].emojisRowsHTML.length === 0" class="w-100 h-100 d-flex align-items-center justify-content-center flex-column text-gray">
        <fa-icon [icon]="faFaceSadTear" size="5x" class="mb-4" (click)="$event.stopPropagation()" />
        <small class="text-gray">{{ 'common.input.emoji-picker.no-results' | translate }}</small>
      </div>
      <div class="selector flex-grow-1" (scroll)="onSelectorScroll()" #selectorScroller style="position: relative;" [class.d-none]="activeCategory === 'search' && visibleEmojis[0].emojisRowsHTML.length === 0">
        <div class="container-wrapper" [style.height]="calculatedTotalHeight + 'px'" style="position: relative;">
          <div 
            class="category-group" 
            *ngFor="let category of visibleEmojis; trackBy:identifyCategory" 
            [style.height]="category.totalHeight + 'px'" 
            [style.top]="category.topOffset + 'px'" 
            style="position: absolute; width: 100%;"
          >
            <div class="group-title d-flex align-items-center" (click)="toggle(category.type)" *ngIf="category.type !== 'search'">
              <div class="d-flex align-items-center me-2">
                <fa-icon *ngIf="category.iconURL === undefined && category.type !== 'guild'" [icon]="category.icon" class="me-2"></fa-icon> 
                <img *ngIf="category.iconURL !== undefined" [ngSrc]="category.iconURL" width="16" height="16" class="me-2" />
                <div class="no-icon me-2" *ngIf="category.iconURL === undefined && category.type === 'guild'">
                  {{ guildName.toUpperCase().charAt(0) }}
                </div>
                <span *ngIf="category.type !== 'guild'">
                  {{ category.nameKey | translate }}
                </span>
                <span *ngIf="category.type === 'guild'">
                  {{ guildName }}
                </span>
              </div>
              <fa-icon [icon]="isRetracted(category.type) ? faChevronRight : faChevronDown"></fa-icon>
            </div>
            <div class="emojis" style="position: relative;">
              <div *ngFor="let row of category.emojisRowsHTML; trackBy:identifyRow" [style.top]="row.topOffset + 'px'" style="position: absolute;" class="emoji-row d-flex align-items-center">
                <div 
                  class="emoji-container" 
                  *ngFor="let emoji of row.emojis; let i = index; trackBy:identifyEmoji" 
                  (mouseenter)="emojiFocus(emoji)"
                  (click)="insertEmoji(emoji)"
                >
                  <app-image-lazy-load [loadDelay]="100 + row.delay + (i * 5)" [width]="32" [height]="32">
                    <img class="emoji" draggable="false" [alt]="emoji.unicode" [ngSrc]="emoji.src" width="32" height="32">
                  </app-image-lazy-load>
                </div>
                <div class="void-emoji-container" *ngFor="let d of [].constructor(row.availableCols); let i = index"></div>
              </div> 
            </div>
          </div>
        </div>
      </div>
      <div class="emoji-preview" *ngIf="activeCategory !== 'search' || activeCategory === 'search' && visibleEmojis[0].emojisRowsHTML.length > 0">
        <img [ngSrc]="focusedEmoji.src" width="32" height="32" class="me-3" /> {{ focusedEmoji.shortName }}
      </div>
    </div>
  </div>
</div>
