import { CommonModule, NgOptimizedImage } from '@angular/common'
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core'
import { FormsModule } from '@angular/forms'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import {
  faBicycle,
  faBurger,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faFaceSmile,
  faFlag,
  faGamepad,
  faHeart,
  faLaptop,
  faLeaf,
  faUpDownLeftRight,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons'
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { InputTextModule } from 'primeng/inputtext'
import { EmojiDirective } from 'src/app/core/directive/emoji.directive'
import { EmojiPipe } from 'src/app/core/pipes/emoji.pipe'
import { ImageLazyLoadComponent } from '../image-lazy-load/image-lazy-load.component'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { generateUUID } from 'src/app/core/utils/app-util'
import { parse } from '@twemoji/parser'
import {
  faCircleXmark,
  faFaceSadTear
} from '@fortawesome/free-regular-svg-icons'
import { EmojiSelectorDirective } from 'src/app/core/directive/emoji-selector.directive'
import { GuildEmojiModel } from 'src/app/core/models/zergrosh-model'
import { DashboardService } from 'src/app/core/services/dashboard.service'
import { AvatarModule } from 'primeng/avatar'

@Component({
  selector: 'app-emoji-selector',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    FontAwesomeModule,
    TranslateModule,
    EmojiPipe,
    EmojiDirective,
    VirtualScrollerModule,
    NgOptimizedImage,
    ImageLazyLoadComponent,
    NgOptimizedImage,
    AvatarModule,
    TranslateModule
  ],
  templateUrl: './emoji-selector.component.html',
  styleUrl: './emoji-selector.component.scss'
})
export class EmojiSelectorComponent implements AfterViewInit, OnInit {
  faFaceSmile = faFaceSmile
  faLeaf = faLeaf
  faBurger = faBurger
  faGamepad = faGamepad
  faBicycle = faBicycle
  faLaptop = faLaptop
  faHeart = faHeart
  faFlag = faFlag
  faChevronDown = faChevronDown
  faChevronUp = faChevronUp
  faChevronRight = faChevronRight
  faFaceSadTear = faFaceSadTear
  faCircleXmark = faCircleXmark
  faUpDownLeftRight = faUpDownLeftRight

  anchorRef!: ElementRef<unknown>
  focusedEmoji!: EmojiParsed
  searchTerm = ''

  retractedCategories: EmojiCategoryType[] = []

  @ViewChild('emojiSelector', { static: false })
  emojiSelector!: ElementRef<HTMLDivElement>

  @ViewChild('selectorScroller', { static: false })
  selectorScroller!: ElementRef<HTMLDivElement>

  @HostBinding('style.left')
  leftPos?: string

  @HostBinding('style.top')
  topPos?: string

  @HostBinding('style.right')
  rightPos?: string

  @HostBinding('style.bottom')
  bottomPos?: string

  @HostBinding('class.mobile-selector')
  mobileSelector = false

  visibleEmojis: VisibleEmojiCategory[] = []
  pendingScrollRender = false
  activeCategory: EmojiCategoryType = 'persons'
  calculatedTotalHeight = 0

  static emojisParsed = false
  static emojisByCategoryParsed: EmojiCategoryParsed[] = []
  static emojisByCategoryCatalog: EmojiCategory[] = [
    {
      type: 'persons',
      icon: faFaceSmile,
      emojisRows: [
        ['😀', '😃', '😄', '😁', '😆', '🥹', '😅', '😂', '🤣'],
        ['🥲', '☺️', '😊', '😇', '🙂', '🙃', '😉', '😌', '😍'],
        ['🥰', '😘', '😗', '😙', '😚', '😋', '😛', '😝', '😜'],
        ['🤪', '🤨', '🧐', '🤓', '😎', '🥸', '🤩', '🥳', '😏'],
        ['😒', '😞', '😔', '😟', '😕', '🙁', '☹️', '😣', '😖'],
        ['😫', '😩', '🥺', '😢', '😭', '😤', '😠', '😡', '🤬'],
        ['🤯', '😳', '🥵', '🥶', '😶‍🌫️', '😱', '😨', '😰', '😥'],
        ['😓', '🤗', '🤔', '🫣', '🤭', '🫢', '🫡', '🤫', '🫠'],
        ['🤥', '😶', '🫥', '😐', '🫤', '😑', '🫨', '😬', '🙄'],
        ['😯', '😦', '😧', '😮', '😲', '🥱', '😴', '🤤', '😪'],
        ['😮‍💨', '😵', '😵‍💫', '🤐', '🥴', '🤢', '🤮', '🤧', '😷'],
        ['🤒', '🤕', '🤑', '🤠', '😈', '👿', '👹', '👺', '🤡'],
        ['💩', '👻', '💀', '☠️', '👽', '👾', '🤖', '🎃', '😺'],
        ['😸', '😹', '😻', '😼', '😽', '🙀', '😿', '😾', '🤝'],
        ['🫶', '🤲', '👐', '🙌', '👏', '👍', '👎', '👊', '✊'],
        ['🤛', '🤜', '🫷', '🫸', '🤞', '✌️', '🫰', '🤟', '🤘'],
        ['👌', '🤌', '🤏', '🫳', '🫴', '👈', '👉', '👆', '👇'],
        ['☝️', '✋', '🤚', '🖐️', '🖖', '👋', '🤙', '🫲', '🫱'],
        ['💪', '🦾', '🖕', '✍️', '🙏', '🫵', '🦶', '🦵', '🦿'],
        ['💄', '💋', '👄', '🫦', '🦷', '👅', '👂', '🦻', '👃'],
        ['👣', '👁️', '👀', '🫀', '🫁', '🧠', '🗣️', '👤', '👥'],
        ['🫂', '👶', '🧒', '👧', '👦', '🧑', '👩', '👨', '🧑‍🦱'],
        ['👩‍🦱', '👨‍🦱', '🧑‍🦰', '👩‍🦰', '👨‍🦰', '👱', '👱‍♀️', '👱‍♂️', '🧑‍🦳'],
        ['👩‍🦳', '👨‍🦳', '🧑‍🦲', '👩‍🦲', '👨‍🦲', '🧔', '🧔‍♀️', '🧔‍♂️', '🧓'],
        ['👵', '👴', '👲', '👳', '👳‍♀️', '👳‍♂️', '🧕', '👮', '👮‍♀️'],
        ['👮‍♂️', '👷', '👷‍♀️', '👷‍♂️', '💂', '💂‍♀️', '💂‍♂️', '🕵️', '🕵️‍♀️'],
        ['🕵️‍♂️', '🧑‍⚕️', '👩‍⚕️', '👨‍⚕️', '🧑‍🌾', '👩‍🌾', '👨‍🌾', '🧑‍🍳', '👩‍🍳'],
        ['👨‍🍳', '🧑‍🎓', '👩‍🎓', '👨‍🎓', '🧑‍🎤', '👩‍🎤', '👨‍🎤', '🧑‍🏫', '👩‍🏫'],
        ['👨‍🏫', '🧑‍🏭', '👩‍🏭', '👨‍🏭', '🧑‍💻', '👩‍💻', '👨‍💻', '🧑‍💼', '👩‍💼'],
        ['👨‍💼', '🧑‍🔧', '👩‍🔧', '👨‍🔧', '🧑‍🔬', '👩‍🔬', '👨‍🔬', '🧑‍🎨', '👩‍🎨'],
        ['👨‍🎨', '🧑‍🚒', '👩‍🚒', '👨‍🚒', '🧑‍✈️', '👩‍✈️', '👨‍✈️', '🧑‍🚀', '👩‍🚀'],
        ['👨‍🚀', '🧑‍⚖️', '👩‍⚖️', '👨‍⚖️', '👰', '👰‍♀️', '👰‍♂️', '🤵', '🤵‍♀️'],
        ['🤵‍♂️', '🫅', '👸', '🤴', '🦸', '🦸‍♀️', '🦸‍♂️', '🦹', '🦹‍♀️'],
        ['🦹‍♂️', '🥷', '🧑‍🎄', '🤶', '🎅', '🧙', '🧙‍♀️', '🧙‍♂️', '🧝'],
        ['🧝‍♀️', '🧝‍♂️', '🧌', '🧛', '🧛‍♀️', '🧛‍♂️', '🧟', '🧟‍♀️', '🧟‍♂️'],
        ['🧞', '🧞‍♀️', '🧞‍♂️', '🧜', '🧜‍♀️', '🧜‍♂️', '🧚', '🧚‍♀️', '🧚‍♂️'],
        ['👼', '🫄', '🤰', '🫃', '🤱', '🧑‍🍼', '👩‍🍼', '👨‍🍼', '🙇'],
        ['🙇‍♀️', '🙇‍♂️', '💁', '💁‍♀️', '💁‍♂️', '🙅', '🙅‍♀️', '🙅‍♂️', '🙆'],
        ['🙆‍♀️', '🙆‍♂️', '🙋', '🙋‍♀️', '🙋‍♂️', '🧏', '🧏‍♀️', '🧏‍♂️', '🤦'],
        ['🤦‍♀️', '🤦‍♂️', '🤷', '🤷‍♀️', '🤷‍♂️', '🙎', '🙎‍♀️', '🙎‍♂️', '🙍'],
        ['🙍‍♀️', '🙍‍♂️', '💇', '💇‍♀️', '💇‍♂️', '💆', '💆‍♀️', '💆‍♂️', '🧖'],
        ['🧖‍♀️', '🧖‍♂️', '💅', '🤳', '💃', '🕺', '👯', '👯‍♀️', '👯‍♂️'],
        ['🕴️', '🧑‍🦽', '👩‍🦽', '👨‍🦽', '🧑‍🦼', '👩‍🦼', '👨‍🦼', '🚶', '🚶‍♀️'],
        ['🚶‍♂️', '🧑‍🦯', '👩‍🦯', '👨‍🦯', '🧎', '🧎‍♀️', '🧎‍♂️', '🏃', '🏃‍♀️'],
        ['🏃‍♂️', '🧍', '🧍‍♀️', '🧍‍♂️', '🧑‍🤝‍🧑', '👫', '👭', '👬', '💑'],
        ['👩‍❤️‍👨', '👩‍❤️‍👩', '👨‍❤️‍👨', '💏', '👩‍❤️‍💋‍👨', '👩‍❤️‍💋‍👩', '👨‍❤️‍💋‍👨', '👪', '👨‍👩‍👦'],
        ['👨‍👩‍👧', '👨‍👩‍👧‍👦', '👨‍👩‍👦‍👦', '👨‍👩‍👧‍👧', '👩‍👩‍👦', '👩‍👩‍👧', '👩‍👩‍👧‍👦', '👩‍👩‍👦‍👦', '👩‍👩‍👧‍👧'],
        ['👨‍👨‍👦', '👨‍👨‍👧', '👨‍👨‍👧‍👦', '👨‍👨‍👦‍👦', '👨‍👨‍👧‍👧', '👩‍👦', '👩‍👧', '👩‍👧‍👦', '👩‍👦‍👦']
      ]
    },
    {
      type: 'nature',
      icon: faLeaf,
      emojisRows: [
        ['🐶', '🐱', '🐭', '🐹', '🐰', '🦊', '🐻', '🐼', '🐻‍❄️'],
        ['🐨', '🐯', '🦁', '🐮', '🐷', '🐽', '🐸', '🐵', '🙈'],
        ['🙉', '🙊', '🐒', '🐔', '🐧', '🐦', '🐤', '🐣', '🐥'],
        ['🪿', '🦆', '🐦‍⬛', '🦅', '🦉', '🦇', '🐺', '🐗', '🐴'],
        ['🦄', '🫎', '🐝', '🪱', '🐛', '🦋', '🐌', '🐞', '🐜'],
        ['🪰', '🪲', '🪳', '🦟', '🦗', '🕷️', '🕸️', '🦂', '🐢'],
        ['🐍', '🦎', '🦖', '🦕', '🐙', '🦑', '🪼', '🦐', '🦞'],
        ['🦀', '🐡', '🐠', '🐟', '🐬', '🐳', '🐋', '🦈', '🦭'],
        ['🐊', '🐅', '🐆', '🦓', '🦍', '🦧', '🦣', '🐘', '🦛'],
        ['🦏', '🐪', '🐫', '🦒', '🦘', '🦬', '🐃', '🐂', '🐄'],
        ['🫏', '🐎', '🐖', '🐏', '🐑', '🦙', '🐐', '🦌', '🐕'],
        ['🐩', '🦮', '🐕‍🦺', '🐈', '🐈‍⬛', '🪶', '🪽', '🐓', '🦃'],
        ['🦤', '🦚', '🦜', '🦢', '🦩', '🕊️', '🐇', '🦝', '🦨'],
        ['🦡', '🦫', '🦦', '🦥', '🐁', '🐀', '🐿️', '🦔', '🐾'],
        ['🐉', '🐲', '🌵', '🎄', '🌲', '🌳', '🌴', '🪵', '🌱'],
        ['🌿', '☘️', '🍀', '🎍', '🪴', '🎋', '🍃', '🍂', '🍁'],
        ['🪺', '🪹', '🍄', '🐚', '🪸', '🪨', '🌾', '💐', '🌷'],
        ['🌹', '🥀', '🪻', '🪷', '🌺', '🌸', '🌼', '🌻', '🌞'],
        ['🌝', '🌛', '🌜', '🌚', '🌕', '🌖', '🌗', '🌘', '🌑'],
        ['🌒', '🌓', '🌔', '🌙', '🌎', '🌍', '🌏', '🪐', '💫'],
        ['⭐', '🌟', '✨', '⚡', '☄️', '💥', '🔥', '🌪️', '🌈'],
        ['☀️', '🌤️', '⛅', '🌥️', '☁️', '🌦️', '🌧️', '⛈️', '🌩️'],
        ['🌨️', '❄️', '☃️', '⛄', '🌬️', '💨', '💧', '💦', '🫧'],
        ['☔', '☂️', '🌊', '🌫️']
      ]
    },
    {
      type: 'food',
      icon: faBurger,
      emojisRows: [
        ['🍏', '🍎', '🍐', '🍊', '🍋', '🍌', '🍉', '🍇', '🍓'],
        ['🫐', '🍈', '🍒', '🍑', '🥭', '🍍', '🥥', '🥝', '🍅'],
        ['🍆', '🥑', '🫛', '🥦', '🥬', '🥒', '🌶️', '🫑', '🌽'],
        ['🥕', '🫒', '🧄', '🧅', '🥔', '🍠', '🫚', '🥐', '🥯'],
        ['🍞', '🥖', '🥨', '🧀', '🥚', '🍳', '🧈', '🥞', '🧇'],
        ['🥓', '🥩', '🍗', '🍖', '🦴', '🌭', '🍔', '🍟', '🍕'],
        ['🫓', '🥪', '🥙', '🧆', '🌮', '🌯', '🫔', '🥗', '🥘'],
        ['🫕', '🥫', '🫙', '🍝', '🍜', '🍲', '🍛', '🍣', '🍱'],
        ['🥟', '🦪', '🍤', '🍙', '🍚', '🍘', '🍥', '🥠', '🥮'],
        ['🍢', '🍡', '🍧', '🍨', '🍦', '🥧', '🧁', '🍰', '🎂'],
        ['🍮', '🍭', '🍬', '🍫', '🍿', '🍩', '🍪', '🌰', '🥜'],
        ['🫘', '🍯', '🥛', '🫗', '🍼', '🫖', '☕', '🍵', '🧉'],
        ['🧃', '🥤', '🧋', '🍶', '🍺', '🍻', '🥂', '🍷', '🥃'],
        ['🍸', '🍹', '🍾', '🧊', '🥄', '🍴', '🍽️', '🥣', '🥡'],
        ['🥢', '🧂']
      ]
    },
    {
      type: 'activities',
      icon: faGamepad,
      emojisRows: [
        ['⚽', '🏀', '🏈', '⚾', '🥎', '🎾', '🏐', '🏉', '🥏'],
        ['🎱', '🪀', '🏓', '🏸', '🏒', '🏑', '🥍', '🏏', '🪃'],
        ['🥅', '⛳', '🪁', '🛝', '🏹', '🎣', '🤿', '🥊', '🥋'],
        ['🎽', '🛹', '🛼', '🛷', '⛸️', '🥌', '🎿', '⛷️', '🏂'],
        ['🪂', '🏋️', '🏋️‍♀️', '🏋️‍♂️', '🤼', '🤼‍♀️', '🤼‍♂️', '🤸', '🤸‍♀️'],
        ['🤸‍♂️', '⛹️', '⛹️‍♀️', '⛹️‍♂️', '🤺', '🤾', '🤾‍♀️', '🤾‍♂️', '🏌️'],
        ['🏌️‍♀️', '🏌️‍♂️', '🏇', '🧘', '🧘‍♀️', '🧘‍♂️', '🏄', '🏄‍♀️', '🏄‍♂️'],
        ['🏊', '🏊‍♀️', '🏊‍♂️', '🤽', '🤽‍♀️', '🤽‍♂️', '🚣', '🚣‍♀️', '🚣‍♂️'],
        ['🧗', '🧗‍♀️', '🧗‍♂️', '🚵', '🚵‍♀️', '🚵‍♂️', '🚴', '🚴‍♀️', '🚴‍♂️'],
        ['🏆', '🥇', '🥈', '🥉', '🏅', '🎖️', '🏵️', '🎗️', '🎫'],
        ['🎟️', '🎪', '🤹', '🤹‍♀️', '🤹‍♂️', '🎭', '🩰', '🎨', '🎬'],
        ['🎤', '🎧', '🎼', '🎹', '🪇', '🥁', '🪘', '🎷', '🎺'],
        ['🪗', '🎸', '🪕', '🎻', '🪈', '🎲', '♟️', '🎯', '🎳'],
        ['🎮', '🎰', '🧩']
      ]
    },
    {
      type: 'travels',
      icon: faBicycle,
      emojisRows: [
        ['🚗', '🚕', '🚙', '🛻', '🚐', '🚌', '🚎', '🏎️', '🚓'],
        ['🚑', '🚒', '🚚', '🚛', '🚜', '🦯', '🦽', '🦼', '🩼'],
        ['🛴', '🚲', '🛵', '🏍️', '🛺', '🛞', '🚨', '🚔', '🚍'],
        ['🚘', '🚖', '🚡', '🚠', '🚟', '🚃', '🚋', '🚞', '🚝'],
        ['🚄', '🚅', '🚈', '🚂', '🚆', '🚇', '🚊', '🚉', '✈️'],
        ['🛫', '🛬', '🛩️', '💺', '🛰️', '🚀', '🛸', '🚁', '🛶'],
        ['⛵', '🚤', '🛥️', '🛳️', '⛴️', '🚢', '🛟', '⚓', '🪝'],
        ['⛽', '🚧', '🚦', '🚥', '🚏', '🗺️', '🗿', '🗽', '🗼'],
        ['🏰', '🏯', '🏟️', '🎡', '🎢', '🎠', '⛲', '⛱️', '🏖️'],
        ['🏝️', '🏜️', '🌋', '⛰️', '🏔️', '🗻', '🏕️', '⛺', '🏠'],
        ['🏡', '🏘️', '🏚️', '🛖', '🏗️', '🏭', '🏢', '🏬', '🏣'],
        ['🏤', '🏥', '🏦', '🏨', '🏪', '🏫', '🏩', '💒', '🏛️'],
        ['⛪', '🕌', '🕍', '🛕', '🕋', '⛩️', '🛤️', '🛣️', '🗾'],
        ['🎑', '🏞️', '🌅', '🌄', '🌠', '🎇', '🎆', '🌇', '🌆'],
        ['🏙️', '🌃', '🌌', '🌉', '🌁']
      ]
    },
    {
      type: 'objects',
      icon: faLaptop,
      emojisRows: [
        ['⌚', '📱', '📲', '💻', '⌨️', '🖥️', '🖨️', '🖱️', '🖲️'],
        ['🕹️', '🗜️', '💽', '💾', '💿', '📀', '📼', '📷', '📸'],
        ['📹', '🎥', '📽️', '🎞️', '📞', '☎️', '📟', '📠', '📺'],
        ['📻', '🎙️', '🎚️', '🎛️', '🧭', '⏱️', '⏲️', '⏰', '🕰️'],
        ['⌛', '⏳', '📡', '🔋', '🪫', '🔌', '💡', '🔦', '🕯️'],
        ['🪔', '🧯', '🛢️', '💸', '💵', '💴', '💶', '💷', '🪙'],
        ['💰', '💳', '🪪', '💎', '⚖️', '🪜', '🧰', '🪛', '🔧'],
        ['🔨', '⚒️', '🛠️', '⛏️', '🪚', '🔩', '⚙️', '🪤', '🧱'],
        ['⛓️', '🧲', '🔫', '💣', '🧨', '🪓', '🔪', '🗡️', '⚔️'],
        ['🛡️', '🚬', '⚰️', '🪦', '⚱️', '🏺', '🔮', '📿', '🧿'],
        ['🪬', '💈', '⚗️', '🔭', '🔬', '🕳️', '🩻', '🩹', '🩺'],
        ['💊', '💉', '🩸', '🧬', '🦠', '🧫', '🧪', '🌡️', '🧹'],
        ['🪠', '🧺', '🧻', '🚽', '🚰', '🚿', '🛁', '🛀', '🧼'],
        ['🪥', '🪒', '🪮', '🧽', '🪣', '🧴', '🛎️', '🔑', '🗝️'],
        ['🚪', '🪑', '🛋️', '🛏️', '🛌', '🧸', '🪆', '🖼️', '🪞'],
        ['🪟', '🛍️', '🛒', '🎁', '🎈', '🎏', '🎀', '🪄', '🪅'],
        ['🎊', '🎉', '🎎', '🪭', '🏮', '🎐', '🪩', '🧧', '✉️'],
        ['📩', '📨', '📧', '💌', '📥', '📤', '📦', '🏷️', '🪧'],
        ['📪', '📫', '📬', '📭', '📮', '📯', '📜', '📃', '📄'],
        ['📑', '🧾', '📊', '📈', '📉', '🗒️', '🗓️', '📆', '📅'],
        ['🗑️', '📇', '🗃️', '🗳️', '🗄️', '📋', '📁', '📂', '🗂️'],
        ['🗞️', '📰', '📓', '📔', '📒', '📕', '📗', '📘', '📙'],
        ['📚', '📖', '🔖', '🧷', '🔗', '📎', '🖇️', '📐', '📏'],
        ['🧮', '📌', '📍', '✂️', '🖊️', '🖋️', '✒️', '🖌️', '🖍️'],
        ['📝', '✏️', '🔍', '🔎', '🔏', '🔐', '🔒', '🔓']
      ]
    },
    {
      type: 'symbols',
      icon: faHeart,
      emojisRows: [
        ['🩷', '❤️', '🧡', '💛', '💚', '🩵', '💙', '💜', '🖤'],
        ['🩶', '🤍', '🤎', '💔', '❣️', '💕', '💞', '💓', '💗'],
        ['💖', '💘', '💝', '❤️‍🩹', '❤️‍🔥', '💟', '☮️', '✝️', '☪️'],
        ['🕉️', '☸️', '🪯', '✡️', '🔯', '🕎', '☯️', '☦️', '🛐'],
        ['⛎', '♈', '♉', '♊', '♋', '♌', '♍', '♎', '♏'],
        ['♐', '♑', '♒', '♓', '🆔', '⚛️', '🉑', '☢️', '☣️'],
        ['📴', '📳', '🈶', '🈚', '🈸', '🈺', '🈷️', '✴️', '🆚'],
        ['💮', '🉐', '㊙️', '㊗️', '🈴', '🈵', '🈹', '🈲', '🅰️'],
        ['🅱️', '🆎', '🆑', '🅾️', '🆘', '❌', '⭕', '🛑', '⛔'],
        ['📛', '🚫', '💯', '💢', '♨️', '🚷', '🚯', '🚳', '🚱'],
        ['🔞', '📵', '🚭', '❗', '❕', '❓', '❔', '‼️', '⁉️'],
        ['🔅', '🔆', '〽️', '⚠️', '🚸', '🔱', '⚜️', '🔰', '♻️'],
        ['✅', '🈯', '💹', '❇️', '✳️', '❎', '🌐', '💠', 'Ⓜ️'],
        ['🌀', '💤', '🏧', '🚾', '♿', '🅿️', '🛗', '🈳', '🈂️'],
        ['🛂', '🛃', '🛄', '🛅', '🛜', '🚹', '🚺', '🚼', '🚻'],
        ['🚮', '🎦', '📶', '🈁', '🔣', 'ℹ️', '🔤', '🔡', '🔠'],
        ['🆖', '🆗', '🆙', '🆒', '🆕', '🆓', '0️⃣', '1️⃣', '2️⃣'],
        ['3️⃣', '4️⃣', '5️⃣', '6️⃣', '7️⃣', '8️⃣', '9️⃣', '🔟', '🔢'],
        ['#️⃣', '*️⃣', '⏏️', '▶️', '⏸️', '⏯️', '⏹️', '⏺️', '⏭️'],
        ['⏮️', '⏩', '⏪', '⏫', '⏬', '◀️', '🔼', '🔽', '➡️'],
        ['⬅️', '⬆️', '⬇️', '↗️', '↘️', '↙️', '↖️', '↕️', '↔️'],
        ['↪️', '↩️', '⤴️', '⤵️', '🔀', '🔁', '🔂', '🔄', '🔃'],
        ['🎵', '🎶', '➕', '➖', '➗', '✖️', '🟰', '♾️', '💲'],
        ['💱', '™️', '©️', '®️', '〰️', '➰', '➿', '🔚', '🔙'],
        ['🔛', '🔝', '🔜', '✔️', '☑️', '🔘', '⚪', '⚫', '🔴'],
        ['🔵', '🟤', '🟣', '🟢', '🟡', '🟠', '🔺', '🔻', '🔸'],
        ['🔹', '🔶', '🔷', '🔳', '🔲', '▪️', '▫️', '◾', '◽'],
        ['◼️', '◻️', '⬛', '⬜', '🟧', '🟦', '🟥', '🟫', '🟪'],
        ['🟩', '🟨', '🔈', '🔇', '🔉', '🔊', '🔔', '🔕', '📣'],
        ['📢', '🗨️', '👁‍🗨', '💬', '💭', '🗯️', '♠️', '♣️', '♥️'],
        ['♦️', '🃏', '🎴', '🀄', '🕐', '🕑', '🕒', '🕓', '🕔'],
        ['🕕', '🕖', '🕗', '🕘', '🕙', '🕚', '🕛', '🕜', '🕝'],
        ['🕞', '🕟', '🕠', '🕡', '🕢', '🕣', '🕤', '🕥', '🕦'],
        ['🕧', '♀️', '♂️', '⚧', '⚕️', '🇿', '🇾', '🇽', '🇼'],
        ['🇻', '🇺', '🇹', '🇸', '🇷', '🇶', '🇵', '🇴', '🇳'],
        ['🇲', '🇱', '🇰', '🇯', '🇮', '🇭', '🇬', '🇫', '🇪'],
        ['🇩', '🇨', '🇧', '🇦']
      ]
    },
    {
      type: 'flags',
      icon: faFlag,
      emojisRows: [
        ['🏳️', '🏴', '🏴‍☠️', '🏁', '🚩', '🏳️‍🌈', '🏳️‍⚧️', '🇺🇳', '🇦🇫'],
        ['🇦🇽', '🇦🇱', '🇩🇿', '🇦🇸', '🇦🇩', '🇦🇴', '🇦🇮', '🇦🇶', '🇦🇬'],
        ['🇦🇷', '🇦🇲', '🇦🇼', '🇦🇺', '🇦🇹', '🇦🇿', '🇧🇸', '🇧🇭', '🇧🇩'],
        ['🇧🇧', '🇧🇾', '🇧🇪', '🇧🇿', '🇧🇯', '🇧🇲', '🇧🇹', '🇧🇴', '🇧🇦'],
        ['🇧🇼', '🇧🇷', '🇮🇴', '🇻🇬', '🇧🇳', '🇧🇬', '🇧🇫', '🇧🇮', '🇰🇭'],
        ['🇨🇲', '🇨🇦', '🇮🇨', '🇨🇻', '🇧🇶', '🇰🇾', '🇨🇫', '🇹🇩', '🇨🇱'],
        ['🇨🇳', '🇨🇽', '🇨🇨', '🇨🇴', '🇰🇲', '🇨🇬', '🇨🇩', '🇨🇰', '🇨🇷'],
        ['🇨🇮', '🇭🇷', '🇨🇺', '🇨🇼', '🇨🇾', '🇨🇿', '🇩🇰', '🇩🇯', '🇩🇲'],
        ['🇩🇴', '🇪🇨', '🇪🇬', '🇸🇻', '🇬🇶', '🇪🇷', '🇪🇪', '🇪🇹', '🇪🇺'],
        ['🇫🇰', '🇫🇴', '🇫🇯', '🇫🇮', '🇫🇷', '🇬🇫', '🇵🇫', '🇹🇫', '🇬🇦'],
        ['🇬🇲', '🇬🇪', '🇩🇪', '🇬🇭', '🇬🇮', '🇬🇷', '🇬🇱', '🇬🇩', '🇬🇵'],
        ['🇬🇺', '🇬🇹', '🇬🇬', '🇬🇳', '🇬🇼', '🇬🇾', '🇭🇹', '🇭🇳', '🇭🇰'],
        ['🇭🇺', '🇮🇸', '🇮🇳', '🇮🇩', '🇮🇷', '🇮🇶', '🇮🇪', '🇮🇲', '🇮🇱'],
        ['🇮🇹', '🇯🇲', '🇯🇵', '🎌', '🇯🇪', '🇯🇴', '🇰🇿', '🇰🇪', '🇰🇮'],
        ['🇽🇰', '🇰🇼', '🇰🇬', '🇱🇦', '🇱🇻', '🇱🇧', '🇱🇸', '🇱🇷', '🇱🇾'],
        ['🇱🇮', '🇱🇹', '🇱🇺', '🇲🇴', '🇲🇰', '🇲🇬', '🇲🇼', '🇲🇾', '🇲🇻'],
        ['🇲🇱', '🇲🇹', '🇲🇭', '🇲🇶', '🇲🇷', '🇲🇺', '🇾🇹', '🇲🇽', '🇫🇲'],
        ['🇲🇩', '🇲🇨', '🇲🇳', '🇲🇪', '🇲🇸', '🇲🇦', '🇲🇿', '🇲🇲', '🇳🇦'],
        ['🇳🇷', '🇳🇵', '🇳🇱', '🇳🇨', '🇳🇿', '🇳🇮', '🇳🇪', '🇳🇬', '🇳🇺'],
        ['🇳🇫', '🇰🇵', '🇲🇵', '🇳🇴', '🇴🇲', '🇵🇰', '🇵🇼', '🇵🇸', '🇵🇦'],
        ['🇵🇬', '🇵🇾', '🇵🇪', '🇵🇭', '🇵🇳', '🇵🇱', '🇵🇹', '🇵🇷', '🇶🇦'],
        ['🇷🇪', '🇷🇴', '🇷🇺', '🇷🇼', '🇼🇸', '🇸🇲', '🇸🇹', '🇸🇦', '🇸🇳'],
        ['🇷🇸', '🇸🇨', '🇸🇱', '🇸🇬', '🇸🇽', '🇸🇰', '🇸🇮', '🇬🇸', '🇸🇧'],
        ['🇸🇴', '🇿🇦', '🇰🇷', '🇸🇸', '🇪🇸', '🇱🇰', '🇧🇱', '🇸🇭', '🇰🇳'],
        ['🇱🇨', '🇵🇲', '🇻🇨', '🇸🇩', '🇸🇷', '🇸🇿', '🇸🇪', '🇨🇭', '🇸🇾'],
        ['🇹🇼', '🇹🇯', '🇹🇿', '🇹🇭', '🇹🇱', '🇹🇬', '🇹🇰', '🇹🇴', '🇹🇹'],
        ['🇹🇳', '🇹🇷', '🇹🇲', '🇹🇨', '🇻🇮', '🇹🇻', '🇺🇬', '🇺🇦', '🇦🇪'],
        ['🇬🇧', '🏴󠁧󠁢󠁥󠁮󠁧󠁿', '🏴󠁧󠁢󠁳󠁣󠁴󠁿', '🏴󠁧󠁢󠁷󠁬󠁳󠁿', '🇺🇸', '🇺🇾', '🇺🇿', '🇻🇺', '🇻🇦'],
        ['🇻🇪', '🇻🇳', '🇼🇫', '🇪🇭', '🇾🇪', '🇿🇲', '🇿🇼', '🇦🇨', '🇧🇻'],
        ['🇨🇵', '🇪🇦', '🇩🇬', '🇭🇲', '🇲🇫', '🇸🇯', '🇹🇦', '🇺🇲']
      ]
    }
  ]

  directive!: EmojiSelectorDirective
  scrollTimeout?: NodeJS.Timeout
  mousemoveEvent?: () => void
  mouseupEvent?: () => void
  dragOffsetX: number = 0
  dragOffsetY: number = 0

  guildEmojis: GuildEmojiModel[] = []
  guildName: string = ''
  guildIconUrl?: string
  guildCategory?: EmojiCategoryParsed

  constructor(
    private hostRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private dashboard: DashboardService,
    private translateService: TranslateService
  ) {
    this.parseEmojis()
  }

  ngAfterViewInit(): void {
    this.retrieveGuildEmojis()
    this.calculateVisibleEmojis()
    this.calculateActiveCategory()
    this.cdr.detectChanges()
  }

  ngOnInit(): void {
    this.focusedEmoji =
      EmojiSelectorComponent.emojisByCategoryParsed[0].emojisRows[0][0]
  }

  // Method that gets triggered on window resize
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.alignToAnchor()
    this.calculateVisibleEmojis()
  }

  retrieveGuildEmojis() {
    if (this.dashboard.getActiveServer() !== null) {
      this.guildEmojis = this.dashboard.getActiveServerEmojis()
      this.guildName = this.dashboard.getActiveServer()?.name ?? ''
      const icon = this.dashboard.getActiveServer()?.icon
      if (icon !== undefined) {
        this.guildIconUrl =
          'https://cdn.discordapp.com/icons/' +
          this.dashboard.getActiveServerId() +
          '/' +
          icon +
          (icon.startsWith('a_') ? '.gif' : '.png')
      }
    }

    if (this.guildEmojis.length > 0) {
      const emojis: EmojiParsed[][] = []

      let row: EmojiParsed[] = []
      for (const guildEmoji of this.guildEmojis) {
        row.push({
          shortName: ':' + guildEmoji.name + ':',
          src:
            'https://cdn.discordapp.com/emojis/' +
            guildEmoji.id +
            (guildEmoji.isAnimated ? '.gif' : '.png'),
          unicode: guildEmoji.id,
          uuid: guildEmoji.id,
          isCustom: true,
          customRef: guildEmoji
        })
        if (row.length === 9) {
          emojis.push(row)
          row = []
        }
      }

      if (row.length > 0) {
        emojis.push(row)
      }

      const activeServer = this.dashboard.getActiveServer()

      this.guildCategory = {
        name: activeServer?.name ?? 'SERVER',
        icon: faBicycle,
        type: 'guild',
        emojisRows: emojis,
        iconURL:
          activeServer?.icon === undefined
            ? undefined
            : 'https://cdn.discordapp.com/icons/' +
              activeServer.id +
              '/' +
              activeServer.icon +
              (activeServer.icon!.startsWith('a_') ? '.gif' : '.png')
      } as EmojiCategoryParsed
    }
  }

  alignToAnchor(alignCompleted: () => void = () => {}) {
    if (window.innerWidth < 992) {
      this.mobileSelector = true
      this.leftPos = 0 + 'px'
      this.bottomPos = 0 + 'px'
      this.rightPos = undefined
      this.topPos = undefined
    } else {
      const doAlign = () => {
        this.rightPos = undefined
        this.bottomPos = undefined

        const anchorBounds = (
          this.anchorRef.nativeElement as HTMLElement
        ).getBoundingClientRect()
        const hostBounds = (
          this.hostRef.nativeElement as HTMLElement
        ).getBoundingClientRect()

        // Determinar la posición top
        let topBound = anchorBounds.top

        // Intentar - derecha - abajo
        let leftBound = anchorBounds.left + anchorBounds.width + 5

        if (leftBound + hostBounds.width > window.innerWidth) {
          // Intentar - izquierda - abajo
          leftBound = anchorBounds.left - hostBounds.width - 5

          if (leftBound < 0) {
            // Intentar - abajo - derecha
            leftBound = anchorBounds.left
            topBound = anchorBounds.top + anchorBounds.height + 5

            if (topBound + hostBounds.height > window.innerHeight) {
              // Intentar - arriba - derecha
              topBound = anchorBounds.top - hostBounds.height - 5
            } else {
              if (leftBound + hostBounds.width > window.innerWidth) {
                // Intentar abajo - izquierda
                leftBound = anchorBounds.right - hostBounds.width
              }
            }
          } else {
            if (topBound + hostBounds.height > window.innerHeight) {
              // Intentar - izquierda - arriba
              topBound =
                anchorBounds.top - hostBounds.height + anchorBounds.height
            }
          }
        } else {
          if (topBound + hostBounds.height > window.innerHeight) {
            // Intentar - derecha - arriba
            topBound =
              anchorBounds.top - hostBounds.height + anchorBounds.height
          }
        }

        this.topPos = topBound + 'px'
        this.leftPos = leftBound + 'px'

        setTimeout(() => {
          alignCompleted()
        }, 2500)
      }

      if (this.mobileSelector) {
        setTimeout(() => {
          doAlign()
        }, 5)
      } else {
        doAlign()
      }

      this.mobileSelector = false
    }
  }

  isRetracted(category: EmojiCategoryType) {
    return this.retractedCategories.find((r) => r === category) !== undefined
  }

  expand(category: EmojiCategoryType) {
    this.retractedCategories = [
      ...this.retractedCategories.filter((r) => r !== category)
    ]
  }

  retract(category: EmojiCategoryType) {
    if (this.isRetracted(category)) return
    this.retractedCategories.push(category)
  }

  toggle(category: EmojiCategoryType) {
    if (this.isRetracted(category)) {
      this.expand(category)
    } else {
      this.retract(category)
    }

    this.calculateVisibleEmojis()
    this.calculateActiveCategory()
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSelectorScroll() {
    this.pendingScrollRender = true
    clearTimeout(this.scrollTimeout)
    this.calculateVisibleEmojis()
    this.calculateActiveCategory()
    this.pendingScrollRender = false
  }

  calculateActiveCategory() {
    const isSearchTerm = this.searchTerm.trim().length > 0
    if (isSearchTerm) {
      this.activeCategory = 'search'
      return
    }

    const scrollTop = this.selectorScroller.nativeElement.scrollTop
    const scrollerBoundStart = scrollTop
    const scrollerBoundEnd =
      scrollerBoundStart +
      this.selectorScroller.nativeElement.getBoundingClientRect().height
    const categories = EmojiSelectorComponent.emojisByCategoryParsed
    let currentHeight = 0

    if (this.guildCategory !== undefined) {
      const categoryTotalHeight = this.isRetracted('guild')
        ? 30
        : 30 + this.guildCategory.emojisRows.length * 40
      const categoryBoundStart = currentHeight
      const categoryBoundEnd = categoryBoundStart + categoryTotalHeight

      if (
        scrollerBoundEnd >= categoryBoundStart &&
        categoryBoundEnd >= scrollerBoundStart
      ) {
        this.activeCategory = 'guild'
        return
      }
    }

    for (const category of categories) {
      const categoryTotalHeight = this.isRetracted(category.type)
        ? 30
        : 30 + category.emojisRows.length * 40
      const categoryBoundStart = currentHeight
      const categoryBoundEnd = categoryBoundStart + categoryTotalHeight

      if (
        scrollerBoundEnd >= categoryBoundStart &&
        categoryBoundEnd >= scrollerBoundStart
      ) {
        this.activeCategory = category.type
        return
      }

      currentHeight += categoryTotalHeight
    }
  }

  calculateVisibleEmojis(scrollTopToUse?: number) {
    const scrollTop =
      scrollTopToUse ?? this.selectorScroller.nativeElement.scrollTop
    const categories = EmojiSelectorComponent.emojisByCategoryParsed

    let scrollerHeight =
      this.selectorScroller.nativeElement.getBoundingClientRect().height
    if (scrollerHeight === 0) {
      // Asumir que mide 306px
      if (this.mobileSelector) {
        scrollerHeight = 220
      } else {
        scrollerHeight = 306
      }
    }
    const scrollerBoundStart = scrollTop
    const scrollerBoundEnd = scrollerBoundStart + scrollerHeight

    let currentHeight = 0
    this.visibleEmojis = []
    let delay = 0
    const isSearchTerm = this.searchTerm.trim().length > 0

    if (isSearchTerm) {
      this.activeCategory = 'search'
      // Mostrar todos los emojis que coincidan
      const searchTerm = this.searchTerm.trim().toLowerCase()

      // Buscar emojis que coincidan
      const matchEmojis: EmojiParsed[] = []

      for (const category of categories) {
        for (const row of category.emojisRows) {
          for (const emoji of row) {
            if (emoji.shortName.toLowerCase().includes(searchTerm)) {
              matchEmojis.push(emoji)
            }
          }
        }
      }

      const newRows: EmojiParsed[][] = []
      let currentRow: EmojiParsed[] = []
      // Cada 9 emojis crear una fila
      for (const emoji of matchEmojis) {
        currentRow.push(emoji)
        if (currentRow.length === 9) {
          newRows.push(currentRow)
          currentRow = []
        }
      }

      if (currentRow.length > 0) {
        newRows.push(currentRow)
      }

      const visibleRows: EmojiVisibleRow[] = []

      let rowIndex = 0
      for (const row of newRows) {
        const rowBoundStart = currentHeight
        const rowBoundEnd = rowBoundStart + 40

        if (
          scrollerBoundEnd >= rowBoundStart &&
          rowBoundEnd >= scrollerBoundStart
        ) {
          visibleRows.push({
            topOffset: currentHeight,
            emojis: row,
            uniqueIdentifier: 'search_r' + rowIndex,
            delay: delay,
            availableCols: 9 - row.length
          })
        }

        rowIndex++
        currentHeight += 40
      }

      this.visibleEmojis.push({
        icon: faBicycle,
        type: 'search',
        topOffset: 0,
        totalHeight: newRows.length * 40,
        emojisRowsHTML: visibleRows,
        nameKey: 'common.input.emoji-picker.categories.search'
      })
    } else {
      let categoriesToUse: EmojiCategoryParsed[]
      if (this.guildCategory === undefined) {
        categoriesToUse = categories
      } else {
        categoriesToUse = [this.guildCategory, ...categories]
      }

      // Modo normal
      for (const category of categoriesToUse) {
        const categoryTotalHeight = this.isRetracted(category.type)
          ? 30
          : 30 + category.emojisRows.length * 40
        const categoryBoundStart = currentHeight
        const categoryBoundEnd = categoryBoundStart + categoryTotalHeight

        currentHeight += 30
        let rowOffsetTop = 0

        if (
          scrollerBoundEnd >= categoryBoundStart &&
          categoryBoundEnd >= scrollerBoundStart &&
          !this.isRetracted(category.type)
        ) {
          // Mostrar esta categoría
          // Calcular emojis a mostrar
          const displayEmojis: EmojiVisibleRow[] = []

          let rowIndex = 0
          for (const row of category.emojisRows) {
            const rowBoundStart = currentHeight
            const rowBoundEnd = rowBoundStart + 40

            if (
              scrollerBoundEnd >= rowBoundStart &&
              rowBoundEnd >= scrollerBoundStart
            ) {
              // Mostrar esta fia
              displayEmojis.push({
                topOffset: rowOffsetTop,
                emojis: row,
                uniqueIdentifier: category.type + '_r' + rowIndex,
                delay: delay,
                availableCols: 9 - row.length
              })
              delay += 50
            }

            rowIndex++
            currentHeight += 40
            rowOffsetTop += 40
          }

          this.visibleEmojis.push({
            ...category,
            totalHeight: categoryTotalHeight,
            emojisRowsHTML: displayEmojis,
            topOffset: categoryBoundStart,
            nameKey: 'common.input.emoji-picker.categories.' + category.type
          })
        } else {
          currentHeight += categoryTotalHeight - 30
          this.visibleEmojis.push({
            ...category,
            totalHeight: categoryTotalHeight,
            emojisRowsHTML: [],
            topOffset: categoryBoundStart,
            nameKey: 'common.input.emoji-picker.categories.' + category.type
          })
        }
      }
    }
    this.calculatedTotalHeight = currentHeight
  }

  identifyCategory(_index: number, item: VisibleEmojiCategory) {
    return item.type
  }

  identifyRow(_index: number, item: EmojiVisibleRow) {
    return item.uniqueIdentifier
  }

  identifyEmoji(_index: number, item: EmojiParsed) {
    return item.uuid
  }

  parseEmojis() {
    if (EmojiSelectorComponent.emojisParsed) return
    EmojiSelectorComponent.emojisParsed = true
    EmojiSelectorComponent.emojisByCategoryParsed = []
    for (const category of EmojiSelectorComponent.emojisByCategoryCatalog) {
      const categoryEmojis: EmojiParsed[][] = []
      for (const row of category.emojisRows) {
        const rowResult: EmojiParsed[] = []
        for (const element of row) {
          rowResult.push({
            unicode: element,
            src: parse(element)[0].url,
            shortName: joypixels.toShort(element),
            uuid: generateUUID(),
            isCustom: false,
            customRef: undefined
          })
        }
        categoryEmojis.push(rowResult)
      }
      EmojiSelectorComponent.emojisByCategoryParsed.push({
        ...category,
        emojisRows: categoryEmojis
      })
    }
  }

  emojiFocus(emoji: EmojiParsed) {
    this.focusedEmoji = emoji
  }

  transform(v: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(v)
  }

  updateSearch(value: string, scrollTopToUse?: number) {
    this.searchTerm = value

    if (scrollTopToUse === undefined) {
      this.selectorScroller.nativeElement.scrollTop = 0
    }

    if (this.searchTerm.trim().length === 0) {
      this.calculateVisibleEmojis(scrollTopToUse)
      this.calculateActiveCategory()
    } else {
      this.calculateVisibleEmojis(scrollTopToUse)
    }
  }

  jumpTo(categoryType: EmojiCategoryType) {
    let scrollTopToUse = 0
    if (this.isRetracted(categoryType)) {
      this.expand(categoryType)
    }
    if (this.guildCategory !== undefined) {
      if (categoryType === 'guild') {
        scrollTopToUse = 0
      } else {
        scrollTopToUse += 30 + this.guildCategory.emojisRows.length * 40
      }
    }
    if (categoryType !== 'guild') {
      for (const category of EmojiSelectorComponent.emojisByCategoryParsed) {
        if (category.type === categoryType) {
          break
        }
        scrollTopToUse += 30
        if (this.isRetracted(category.type)) continue
        scrollTopToUse += category.emojisRows.length * 40
      }
    }
    this.updateSearch('', scrollTopToUse)
    setTimeout(() => {
      this.selectorScroller.nativeElement.scrollTop = scrollTopToUse
    }, 1)
  }

  startDrag(event: MouseEvent) {
    event.preventDefault()

    if (this.mobileSelector) return

    const currentPosition =
      this.emojiSelector.nativeElement.getBoundingClientRect()
    this.dragOffsetX = event.pageX - currentPosition.x
    this.dragOffsetY = event.pageY - currentPosition.y

    this.mousemoveEvent = this.renderer.listen(
      'document',
      'mousemove',
      this.dragging.bind(this)
    )
    this.mouseupEvent = this.renderer.listen(
      'document',
      'mouseup',
      this.mouseup.bind(this)
    )
  }

  dragging(event: MouseEvent) {
    event.preventDefault()

    if (this.mobileSelector) {
      this.mouseup()
      return
    }

    const bounds = this.emojiSelector.nativeElement.getBoundingClientRect()
    let calculatedTop = event.pageY - this.dragOffsetY
    let calculatedLeft = event.pageX - this.dragOffsetX

    if (calculatedTop + bounds.height >= window.innerHeight) {
      calculatedTop = window.innerHeight - bounds.height
    }

    if (calculatedLeft + bounds.width >= window.innerWidth) {
      calculatedLeft = window.innerWidth - bounds.width
    }

    if (calculatedLeft < 0) {
      calculatedLeft = 0
    }

    if (calculatedTop < 0) {
      calculatedTop = 0
    }

    this.topPos = calculatedTop + 'px'
    this.leftPos = calculatedLeft + 'px'
  }

  mouseup() {
    // Remove listeners
    if (this.mousemoveEvent !== undefined) {
      this.mousemoveEvent()
    }
    if (this.mouseupEvent !== undefined) {
      this.mouseupEvent()
    }
  }

  insertEmoji(emoji: EmojiParsed) {
    if (emoji.isCustom) {
      this.directive.getOwner().insertEmbed({
        type: 'dc-custom-emoji',
        real_value:
          '<' +
          (emoji.customRef!.isAnimated ? 'a' : '') +
          ':' +
          emoji.customRef!.name +
          ':' +
          emoji.customRef!.id +
          '>',
        value: emoji.unicode,
        customEmojiAnimated: emoji.customRef?.isAnimated ?? false,
        customEmojiId: emoji.customRef!.id
      })
    } else {
      this.directive.getOwner().insertEmbed({
        type: 'dc-emoji',
        real_value: emoji.unicode,
        value: emoji.unicode
      })
    }
  }
}

export type EmojiCategoryType =
  | 'guild'
  | 'persons'
  | 'nature'
  | 'food'
  | 'activities'
  | 'travels'
  | 'objects'
  | 'symbols'
  | 'flags'
  | 'search'

export interface EmojiCategory {
  type: EmojiCategoryType
  icon: IconDefinition
  emojisRows: string[][]
}

export interface EmojiCategoryParsed {
  type: EmojiCategoryType
  icon: IconDefinition
  emojisRows: EmojiParsed[][]
  iconURL?: string
}

export interface VisibleEmojiCategory {
  topOffset: number
  type: EmojiCategoryType
  icon: IconDefinition
  emojisRowsHTML: EmojiVisibleRow[]
  totalHeight: number
  iconURL?: string
  nameKey: string
}

export interface EmojiVisibleRow {
  topOffset: number
  emojis: EmojiParsed[]
  uniqueIdentifier: string
  delay: number
  availableCols: number
}

export interface EmojiParsed {
  unicode: string
  src: string
  shortName: string
  uuid: string
  isCustom: boolean
  customRef?: GuildEmojiModel
}
